.progress-notes .ant-collapse-header {
    color: $primary !important;
}

.progress-notes .anticon-right.ant-collapse-arrow {
    top: 7.0005px !important;
}

.progress-notes .text-dark {
    font-size: 16px !important;
}

.accordianstyle {
    margin-bottom: 10px;
    overflow: hidden;
    background: #ebebeb;
    border: 1px solid #cacaca;
    border-radius: 2px;
    font-size: 1.25em;
}

.accordianRow {
    border: 1px solid #cacaca;
    border-radius: 5px;
    background-color: #eff0f0;

}

.collapseStyle {
    border: 1px solid $white;
    background-color: #ffffff;
}

.overFlowScroll {
    max-height: 400px;
    overflow: scroll;
}

.fontWeightClass {
    color: #d24350;
    font-weight: 500 !important;
}