header {
  margin: 0 auto;

  .navbar-dark {
    background-color: $color_text_default;

    .nav-item {
      padding: 0;
      position: relative;



      .nav-link {
        padding: 0.7rem 1.25rem;
        border-right: none;
        font-size: 1rem;
        z-index: 1;
        position: relative;

        &:hover {
          color: $color_white;
          background-color: $primary;
        }

        &.active {
          background-color: $primary;
        }
      }

      &:not(:last-child):after {
        content: "";
        display: block;
        position: absolute;
        right: -1px;
        top: 15px;
        width: 1px;
        height: 18px;
        background: #fff;
      }

      &:hover {
        background-color: transparent;

        .nav-link {
          background-color: $primary;
        }

        &:after {
          right: 0px;
        }

      }
    }

    /* .navbar-nav:last-child .nav-item{border-radius: 5px;} */
    .menu-dropdown .dropdown-menu {
      background-color: $color_text_default;
      margin: 0px;
      padding: 0px;
      width: 240px;
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);

      .dropdown-item {
        padding: 0.5rem 1.25rem;
        display: block;
        line-height: 1.4;
        color: #fff;

        &:not(:last-child) {
          border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        }

        &.active {
          background: $primary;
        }
      }
    }
  }
}

#page_header .navbar-nav .dropdown-menu .dropdown-item {

  &:focus,
  &.active {
    background-color: $primary;
  }
}

.alert-dropdown {
  max-height: 400px;
  width: 545px;
  overflow-y: auto;
  display: block;
  padding: 0 !important;
  background: #fff;

  .dropdown-header {
    padding-top: 7px;
    padding-bottom: 7px;
    height: 40px;
    z-index: 1;
    position: sticky;
    top: 0;
  }
}

.alert-button {
  .ant-badge {
    position: absolute !important;
    right: 0;
    top: 0;
  }
}