.color-red {
  color: #d24350 !important;
}

.panelcol {
  background: #d9d9d9;
}

.textwrap {
  height: 499px;
  max-height: 249px;
}

.key__finding .ant-picker-input > input:focus,
.key__finding .ant-picker-input > input-focused {
  border-color: #d24350;
  box-shadow: none;
}

.key__finding .ant-picker-input > input:hover {
  border-color: #d24350;
  box-shadow: none;
}

.key__finding .form-group {
  width: 250px;
  margin-bottom: 0;
}

.key__finding div.center__align {
  display: flex;
  align-items: center;
}

.key__finding div.center__align:nth-child(3) {
  justify-content: flex-end;
}

.key__finding .ant-picker-focused,
.key__finding .ant-picker-focused:hover {
  border-color: #d24350;
  border-right-width: 1 !important;
  outline: 0;
  box-shadow: none;
}

.key__finding .ant-picker:hover {
  border-color: #d24350;
  box-shadow: none;
}

.since_length{
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  min-height: 44px !important;
}

.ant-select-multiple .ant-select-selection-item { height: auto !important; }
.subjective-form .emrfrm .ant-input-group-addon { background-color: transparent; }
.subjective-form .ant-input-group-addon { background-color: transparent; border: none; }
.months-arrow .ant-select-selection-item { margin-top: 6px;}

.show-placeholder::-webkit-input-placeholder {
color: #666;
}
.show-placeholder:-ms-input-placeholder {
color: #666;
}
.show-placeholder::-ms-input-placeholder {
color: #666;
}
.show-placeholder::-moz-placeholder {
color: #666;
}
.show-placeholder::placeholder {
color: #666;
}

.checkout-token-card {
  border-radius: 8px;
  padding: 0.2rem 0.8rem;
  font-size: 0.85rem;
  font-weight: bold;
  margin: 0px;
  background-color: #307CC6;
  color: #ffffff;
  border: none;
}
.ant-image-preview-operations { background: rgba(0,0,0,1) !important; }