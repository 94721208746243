.d_timeline{margin: 0px; padding: 0px; list-style: none; width: 100%; float: left; position: relative;
	li{position: relative; float: left; width: 100%; padding-bottom: 20px;
		.content{width: 44%;
			&.left{float: left; text-align: right;}
			&.right{float: right;}
		}
		&::before{content: ''; display: block; position: absolute; width: 0.9rem; height: 0.9rem; border-radius: 50%; background: $pending; left: calc(50% - 0.45rem); }
		&:last-child{padding-bottom: 0px;}
		&.completed::before{background-color: $completed;}
		&.inprogress::before{background-color: $inprogress;}
		&.completed-late::before{background-color: $completedlate;}
		&.arrow::after{content: ''; display: block; position: absolute; left: calc(50% - 0.4rem); top:50%; width: 0px; height: 0px; border-left: 0.4rem solid transparent;
				border-right:0.4rem solid transparent; border-top:0.6rem solid $pending;
		}
	}
	&::before{content: ''; display: block; position: absolute; left:calc(50% - 0.05rem); width: 1px; height: calc(100% - 1rem); border-left: dotted 0.1rem $pending; top:10px}

}

.d_timeline_status{margin: 0px; padding: 0px; list-style: none; width: 100%; float: left; position: relative;
	li{position: relative; float: left; width: 100%; padding-bottom: 1rem; line-height: 1;
		i{width: 0.9rem; height: 0.9rem; background:$pending; border-radius: 50%; display: block; float:left; margin-right: 0.5rem;}
		&.completed i{background-color: $completed;}
		&.inprogress i{background-color: $inprogress;}
		&.completed-late i{background-color: $completedlate;}
	}
}