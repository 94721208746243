.form-group:not(.material) {
    position: relative;


    .control-label {
        position: absolute;
        top: -10px;
        left: .5rem;
        font-size: .875rem;
        color: $primary;
        position: absolute;
        background: $white;
        padding: 0 .35rem;
        z-index: 1;
    }

    &.normal {
        .control-label {
            position: static;
            padding: .15rem 0;
        }

        .form-control {
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, .2);
            border-radius: 0;
            padding-left: 0;
            padding-bottom: .75rem;
        }

        .input-group {

            .input-group-append,
            .input-group-prepend {
                .input-group-btn {
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid rgba(0, 0, 0, .2);
                }
            }
        }
    }
}

.card {
    &.bg-grey {

        .form-group:not(.normal) {
            .control-label {
                background-color: $bg-grey;
            }
        }

        .form-control {
            background-color: transparent;
        }
    }
}

.custom-control {
    &.custom-radio {

        .custom-control-label {
            font-size: 1rem;
            line-height: 1.75rem;

            &.small {
                font-size: .9375rem;
            }
        }

        .custom-control-label::before {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 4px;
            border-color: $color_text_default;
            background-color: transparent;
        }

        .custom-control-input:checked {
            ~.custom-control-label::after {
                background-image: none;
                background-color: $color_text_default;
                width: 0.65rem;
                height: 0.65rem;
                border-radius: 50%;
                top: 0.55rem;
                left: -1.2rem;
            }
        }
    }
}

select.form-control {
    appearance: none;
    background: url(../images/ic-arrow-down.svg) right/35px 14px no-repeat!important;
    padding-right: 25px;

}
select.form-control.bg-white{
    background-color: #fff!important;
}
.form-control.bg-grey{
    background-color: $bg-grey!important;
}
/*------------------------------------------------------
Material Design Button 
-------------------------------------------------------*/
.material {
    position: relative;
    margin-bottom: 2rem;

    .form-control::-webkit-input-placeholder {
        color: transparent;
    }

    .form-control:-ms-input-placeholder {
        color: transparent;
    }

    .form-control::-ms-input-placeholder {
        color: transparent;
    }

    .form-control::-moz-placeholder {
        color: transparent;
    }

    .form-control::placeholder {
        color: transparent;
    }

    .control-label {
        display: block;
        position: absolute;
        left: 12px;
        top: 7px;
        margin: 0;
        transition: all .1s ease-in-out;
        z-index:9
    }

    .form-control {
        appearance: none;

        &:disabled{background-color: #ffffff;  color: #ccc; border-color: #ececec;}
    }

    &--is-active,
    &--is-filled {
        .control-label {
            @include label_active();
        }
    }
}

.input-with-icon {
    position: relative;

    >.icon {
        position: absolute;
        right: 4px;
        top: 7px;
    }
}

/*--------------------------------------------------------
Select2 select related
---------------------------------------------------------*/
.form-group {
    .select2-container--default {
        .select2-selection--single {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid rgba(0, 0, 0, .2);
            height: 32px;
        }
    }
}

.select2-container--default {

    .select2-selection--single {
        .select2-selection__rendered {
            padding-left: 0 !important;
            padding-right: 2rem !important;
        }
    }
    .select2-results__option--highlighted[aria-selected]{ background-color: $primary!important;
        .icon_star::after{ @include star_icon($fill_white);}
        .icon_star.favourite::after{ @include fillstar_icon('fb8805');}
    }

}

.btn.focus, .btn:focus{box-shadow: none;}
.cursor-pointer{cursor: pointer;}
.form-control:disabled, .form-control[readonly] {
    background-color: #f9f9f9!important;
    opacity: 1;
}

.form-control .fa-caret-down{position: absolute; width: 16px; height: 16px; right:8px; top: 12px; z-index: 9; 
    &::after{
        content: ''; display: block; position: relative;
		@include arrow_icon($fill_secondary);
		background-repeat: no-repeat;
		background-size: cover;
		width:100%; height: 100%;
    }

}