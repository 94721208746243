.bed_list_status{margin: 0px; padding: 0px; list-style: none; float: left; position: relative; margin-right: 2.5rem;
	li{position: relative; float: left; width: auto; padding-bottom: 1rem; line-height: 1; font-size: 0.9rem; margin-right: 2.5rem;
		i{width: 0.9rem; height: 0.9rem; background:$pending; border-radius:0.2rem; display: block; float:left; margin-right: 0.3rem;}
		&.booked i{background-color: $booked;}
		&.allotted i{background-color: $allotted;}
		&.vacant i{background-color: $vacant;}
		&.occupied i{background-color: $occupied;}
	}
}
.bed_list{margin:0px; padding: 0px; list-style: none; float: left; position: relative; width: 100%;
	li{ width: 10%; text-align: center; float: left; padding:0.75rem 1rem .75rem 0; font-size: 0.9rem;
		div{position: relative; min-height: 50px; margin-bottom: 0.25rem;
			input[type="radio"]{opacity: 0;}
			input[type="radio"]~label{border: solid 0.12rem $vacant; padding: 0.5rem; width: 100%; position: absolute; left: 0px; top: 0px; height: 100%; border-radius: 0.25rem;
				@include bed_icon($fill_vacant); background-repeat: no-repeat; background-position: center center; cursor: pointer;
			}
			input[type="radio"]:checked~label{border-color: $allotted; @include bed_icon($fill_allotted);}
			input[type="radio"].booked~label{border-color: $booked; @include bed_icon($fill_booked); cursor: default;}
			input[type="radio"].occupied~label{border-color: $occupied; @include bed_icon($fill_occupied); cursor: default;}
		}

	}

}