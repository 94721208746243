.tickRight {
    height: 20px;
    width: 10px;
    display: inline-block;
    border: 2px solid #25a71b;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    margin-right: 12px;
    margin-left: -24px;
    margin-bottom: -3px;
  }