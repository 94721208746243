.chat_row_right div {
  border-radius: 50%;
  background: #d24350;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  font-weight: 700;
  margin-right: 25px;
  color: #fff;
}

/** PUBNUB POPUP BODY CSS **/
.modal-dialog.pubnub-popup.modal-xl {
  max-width: 50%;
}

.modal-dialog.pubnub-popup.modal-xl .modal-content {
  border-radius: 1.3rem;
}

.pubnub-appwrapper {
  max-width: 100%;
  margin: 0 auto;
}

.pubnub-common {
  flex: 1;
  padding: 0 15px;
  position: relative;
  box-shadow: 0 0 12px 2px rgba(51, 51, 51, 0.1);
  z-index: 1;
}

.pubnub-wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 100px);
  overflow: hidden;
  background: #fff;
  border-radius: 20px;
}

.pubnub-common.pubnub-content {
  flex: 10;
  display: flex;
  flex-flow: column-reverse nowrap;
  overflow-y: scroll;
  padding-bottom: 10px;
  background: #d0d0d0;
  box-shadow: none;
  z-index: 0;
}

.pubnub-common.pubnub-header {
  min-height: 70px;
  display: flex;
  align-items: center;
}

.pubnub-common.pubnub-header>a {
  position: absolute;
  top: 0px;
  right: 15px;
  font-size: 24px;
  color: #333;
}

.pubnub-common.pubnub-footer {
  min-height: 65px;
  display: flex;
  align-items: stretch;
}

.pubnub-common.pubnub-footer input {
  border: none;
}

.pubnub-title {
  letter-spacing: 0.36px;
  text-align: left;
  color: #666666;
  font-weight: 500;
  font-size: 16px;
}

.pubnubheader-patientdetail {
  position: absolute;
  bottom: 7px;
  right: 20px;
  font-size: 14px;
}

.pubnubheader-patientdetail strong {
  font-weight: 500;
}

.pubnub_footer_buttonwrapper button.icon-mute-btn {
  position: relative;
  top: initial;
  right: initial;
  padding: 0;
  border: none;
  background-color: #fff;
}

.pubnub_footer_buttonwrapper button.icon-mute-btn img {
  width: 20px;
}

.pubnub_footer_buttonwrapper button {
  margin: 0 5px;
}

.pubnub_footer_buttonwrapper {
  display: flex;
  align-items: center;
}

.pubnub-contentinner-content {
  color: #333333;
  display: inline-block;
  border-radius: 20px;
  margin: 5px 0;
  padding: 8px 15px;
  width: 60%;
  word-break: break-all;
}

.pubnub-content-innerwrapper {
  display: block;
}

.pubnub-contentinner-content p span {
  color: #5a5a5a;
  font-size: 14px;
}

.pubnub-contentinner-content p {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pubnub-common.pubnub-content::-webkit-scrollbar-thumb {
  background: #fff;
}

.file-modalpopup-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  padding: 20px 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  background: #fff;
}

.file-modalpopup-wrapper .pip {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-modalpopup-wrapper .pip.pdf {
  height: 100%;
}

.file-modalpopup-wrapper #file-modalpopup {
  width: 100%;
  height: 75%;
  padding: 20px;
}

.file-modalpopup-wrapper .pip object {
  max-width: 100%;
  max-height: 100%;
}

.file-modalpopup-wrapper .pip object[type="application/pdf"] {
  height: 100%;
  width: 100%;
}

.chat_row_right img {
  width: 20px;
}

.chat_row_right div {
  border-radius: 50%;
  background: #d24350;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  font-weight: 700;
  margin-right: 25px;
  color: #fff;
}

.pubnub.dropdown-item:active {
  background-color: #fff;
  color: #333;
}