.notes-wrapper {
    background: $white;
    border: 0;
}

.notes-panel {
    background: $color_smoke_gray;
    margin-bottom: 12px;
    border: 1px solid #d9d9d9;

    .ant-collapse-header {
        font-size: 1rem;
        font-weight: 500;
    }

    .ant-collapse-content-box {
        padding: 0;
    }

    .patient-textwrapper {
        textarea {
            width: 100%;
            border: 0;
            padding: 10px;
        }
    }

    .patient-lastdatacard {
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.125);

        .pl-bottom {
            text-align: right;

            span {
                font-size: 14px;
                color: $primary;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .pl-center {
            font-size: 15px;
            max-height: 85px;
            margin-bottom: 0;
            overflow: hidden;
            white-space: pre-wrap;
            overflow-y: auto;
        }
    }
}

.patient-textwrapper {
    position: relative;

    button {
        position: absolute;
        right: 5px;
        bottom: 5px;
        background: $white;
        z-index: 1;
    }
}

.alert-modal {
    .ant-modal-header {
        background-color: #fadb40 !important;
        padding-bottom: 12px;
    }

    &-subheader {
        padding: .4vw 0;
        display: flex;
        align-items: center;
        background-color: #5c5c5c;
        color: $white;
    }

    &-notes-wrapper {
        max-height: 66.66667vh;
        overflow: hidden;
        overflow-y: auto;
        width: calc(100% + 10px);
        padding-right: 10px;
    }
}

.allergies_list_item {
    position: relative;

    .edit-img {
        max-width: 15px;
        top: 0;
        position: absolute;
        right: 0;
        cursor: pointer;
    }

    .allergy-img {
        top: -12px;
    }
}


.allergy {

    &-listwrapper {
        height: 100%;
        width: calc(100% + 15px);
        padding-right: 15px;
        overflow: hidden;
        overflow-y: auto;
    }

    &-mapping {
        height: calc(100vh - 300px);
        min-height: 520px;

        >div {
            &:first-child {
                height: calc(100% - 70px);
            }
        }

        .am-knownallergy {
            &:first-child {
                height: calc(100% - 112px);
            }
        }
    }

    &-severity {
        position: absolute;
        bottom: 59px;
    }

    &-button-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% + 2px);
        text-align: center;
        background: $white;
        padding: 10px 0;
        border: solid 1px rgba(0, 0, 0, .125);
        border-top: solid 1px $white;
    }
}

.txt-hgt {
    height: 500px;
}