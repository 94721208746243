$primary: #d24350;
$primary-border: #b4101f;
$success: #00aa4c;
$warning: #cc9103;
$orange: #ff8800;
$bg_dark: #343a40;
$color_white:#fff;
$color_gray_border: #ccc;
$color_smoke_gray: #fafafa;
$color_text_default:#5c5c5c;
$color_table_header:#8c8c8c;
$color_alice_blue:#f0f4f7;
$lightgrey: #eff0f0;

// buttons with constant colors as per variants such as MHVC or MAX
$color_constant: #d24350;

$primary-light: #6699cc;
$table-primary:#7eb1e5;
$default: #666;
$secondary: #999;

$light-green: #95dab4;
$danger: #ff5252;
$danger-light: #ff5c5c;
$danger-dark: #eb2d2e;
$black: #000;
$white: #fff;
$grey: #333;

$bg-grey: #f4f4f4;
$navbarColor: #609ddb;
$navbarActiveColor: #126dcb;
$shadowColor: rgba(211, 211, 211, .33);
$borderColor: #dddbdb;
$bg_alice_blue: #f6f8fa;
$bg-warning-bg:#fbf0c9;
$bg-warning-border:#f0cb4a;


/* Bed Color*/
$booked: $primary;
$allotted: #adadad;
$vacant: $success;
$occupied:$orange;


/* Time Line */
$completed:$success;
$inprogress: $primary;
$completedlate: $orange;
$pending:#adadad;



/* Slots color Code*/
$slotAvailable: #007aff;
$slotBooked: #69b75d;
$slotBlocked: #f09898;
$slotLapsed: #a3a3a3;
$patientCompleted: #00a950;
$patientArrived: #f2980a;
$patientCancelled: #eb2d2e;
$patientNoShow: #126dcb;



/**
    Code Alert related
*/
$codeBlue: #0b5ab2;
$codeRed: #eb2d2e;
$codeYellow: #fec30a;
$codePurple: #5f007f;
$codeBlack: #000000;
$codePink: #f53fc7;

/**
    Todays visit dashboard
*/
$subjective: #3ebda0;
$objective: #ed8c21;
$assessment: #e74f66;
$plan: #6699cc;

$breakpoint-sm: 768px;


$fill_primary: 'd24350';
$fill_secondary: '5c5c5c';
$fill_success: '00aa4c';
$fill_white: 'ffffff';
$fill_black: '000000';

$fill_booked: 'd24350';
$fill_vacant: '00aa4c';
$fill_allotted: 'adadad';
$fill_occupied: 'ff9900';

$fill_severity_low: '126dcb';
$fill_severity_medium: 'ff8800';
$fill_severity_high: 'eb2d2e';
$fill_severity_gray: '5c5c5c';