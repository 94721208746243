.textis {
    &-primary {
        color: $primary !important;
    }

    &-black {
        color: #000 !important;
    }
}

.bgis {
    &-pink {
        background-color: rgb(255, 230, 232) !important;
    }
}