.incoming-call-widget { 
    position: fixed; 
    right: 18%;
    top: 1.5%;
    background-color: #5c5c5c;
    color: #ffffff;
    /* background-color: #ffffff;  */
    /* box-shadow: 0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16); */
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    /* border: 2px solid #28a745; */
    width: 700px;
    z-index: 1000;
}
.call-header { padding: 3px 15px;font-size: 14px; }
.call-body {
    padding: 2px 15px;
}
.media-body-txt{font-size: 14px;text-align: center;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.call-body .media-body h5 {
    font-size: 1rem;
    margin-bottom: 0;
}
.close-inccall {
    cursor: pointer;
    padding: 2px 7px;
}
.close-inccall:hover {
    background-color: #e7e7e7;
}
.call-footer {
    padding: 0 15px 15px 50px;
    /* border-top: 1px solid #e6e6e6; */
}
.call-footer button {
    margin-right: 10px;
    width: 100px;
}
.closingin {
    font-size: 14px;
    margin-top: 3px;
    /* color: #ccc; */
}
.phoneic {font-size: 25px;transform: rotate(100deg); -ms-transform: rotate(100deg); -webkit-transform: rotate(100deg);}
.phoneic > span {margin-right: 10px;margin-top: 20px;}
.receive-call > span {font-size: 20px;transform: rotate(90deg); -ms-transform: rotate(90deg); -webkit-transform: rotate(90deg);}
.reject-call > span {font-size: 20px;transform: rotate(-135deg); -ms-transform: rotate(-135deg); -webkit-transform: rotate(-135deg);}
.d-none { display: none; }
.call-header-btns{ width: 430px;text-align: center;display: inline-block; }
.call-header-btns > button {margin-right: 5px;width: 100px;}