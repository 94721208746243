.ant-btn.nostyle-link {
    background: none;
    border: none;
    &.h-auto {
        height: auto;
    }
    &:hover,
    &:focus,
    &:visited {
        border: none;
        outline: none;
    }
}

.custom-checkbox-favourite .custom-control-label.favorite:before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 53.867 53.867' xml:space='preserve'%3E%3Cpolygon style='fill:%23fb8805;' points='26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 '/%3E%3C/svg%3E");
    background-color: #fff;
}

.custom-checkbox .custom-control-label.taper::after {
    background-image: none;
    border-bottom: 0.1rem solid $primary;
    border-right: 0.1rem solid $primary;
    transform: rotate(45deg);
    position: absolute;
    width: 0.3rem;
    height: 0.7rem;
    top: 0.35rem;
    left: -1.15rem;
}

input.input-template {
    color: #6c757d !important;
    &::placeholder {
        color: #6c757d;
    }
}

.ant-btn.nostyle-link.text-underline {
    span {
        font-size: 16px;
        text-decoration: underline;
    }
}

.taper_dose_modal {
    .ant-modal-header {
        padding-left: 15px;
    }
    .ant-modal-title {
        font-size: 1.125rem;
        padding: 20px 20px 20px 0px;
    }
    .ant-modal-footer {
        text-align: center;
        padding: 20px;
        border: none;
    }
}

.dosage-form-text-Modal {
    width: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #eff0f0;
    border: 1px solid #ccc;
}

label.special-label-T {
    display: none !important;
}

label.label-star[for="favourite"]::before {
    left: 0px
}

label.special-label-open {
    display: block !important;
    position: absolute;
    left: 6px;
    top: -7px !important;
    height: 14px !important;
    color: $primary !important;
    font-size: 13px !important;
    line-height: 1;
    background: -moz-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
    background: -webkit-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
    background: linear-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
}

textarea.input-template:focus+label.special-label-T,
input.input-template:focus+label.special-label-T {
    display: block !important;
    top: -7px !important;
    height: 14px !important;
    color: $primary !important;
    font-size: 13px !important;
    line-height: 1;
    background: -moz-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
    background: -webkit-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
    background: linear-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
}

.drug-input,
.select-drugs,
.select-diagnosis,
.drug-Dosage {
    &.ant-select-focused+label.special-label-T {
        display: block !important;
        top: -7px !important;
        height: 14px !important;
        color: $primary !important;
        font-size: 13px !important;
        line-height: 1;
        background: -moz-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
        background: -webkit-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
        background: linear-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
    }
}

.input_taper+label.special-label-T {
    display: block !important;
    top: -7px !important;
    height: 14px !important;
    color: $primary !important;
    font-size: 13px !important;
    line-height: 1;
    background: -moz-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
    background: -webkit-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
    background: linear-gradient(to top, white 50%, rgba(white, 0) 0%) !important;
}

.drug-input.drug-Dosage {
    flex: auto;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: $primary;
}

.ant-radio-input:focus+.ant-radio-inner {
    -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}

.ant-radio-checked::after {
    border: 1px solid $primary;
    border-radius: 50%;
}

.ant-radio-inner::after {
    position: absolute;
    top: 8px;
    left: 8px;
    display: table;
    background-color: $primary;
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.ant-radio-inner {
    width: 1.25rem;
    height: 1.25rem;
}

.ant-radio-checked .ant-radio-inner {
    border-color: $primary;
}

.ant-radio-wrapper {
    font-size: 1rem;
    line-height: 1.75rem;
}

.ant-btn.nostyle-link {
    height: auto;
}

.daysinweek,
.schedules {
    &.ant-select-show-search.ant-select-multiple .ant-select-selector {
        min-height: 44px;
        height: auto;
        max-height: 200px;
    }
}

.myDrugsInteraction_modal {
    max-width: 900px !important;
    width: 100% !important;
}

.instructions_modal {
    max-width: 700px !important;
    width: 100% !important;
    .ant-modal-title {
        padding: 20px 0px 0px 0px;
        font-weight: bold;
        color: #5c5c5c;
    }
    .ant-modal-footer {
        display: flex;
        justify-content: center;
    }
}

.myDrugInfo_modal {
    max-width: 900px !important;
    width: 100% !important;
}

.image_reference_dialog {
    max-width: 1180px !important;
    width: 100% !important;
    .ant-modal-title {
        padding: 20px 0px 0px 0px;
        text-align: left;
        font-weight: bold;
        color: #5c5c5c;
    }
}

.alert_confirm_message {
    .ant-modal-title {
        padding: 20px;
        text-align: center;
        font-weight: bold;
        color: #5c5c5c;
    }
    .ant-modal-footer {
        display: flex;
        justify-content: center;
    }
}

.image_annotation_dialog {
    max-width: 1040px !important;
    width: 100% !important;
}

.ant-tabs-tab {
    border: 1px solid #ccc;
    border-radius: 6px 0 0 6px;
    padding: 0.25rem 0.5rem;
    background-color: #eff0f0;
    margin: 0px !important;
    width: 203px;
    display: inline-flex;
    justify-content: center;
    height: 32px;
    color: #5c5c5c;
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-btn:hover {
        color: #5c5c5c;
    }
    &.ant-tabs-tab-active {
        background-color: #fff;
        color: $primary;
        .ant-tabs-tab-btn {
            color: $primary;
            &:focus,
            &:hover {
                color: $primary;
            }
        }
    }
    &:nth-child(2) {
        border: 1px solid #ccc;
        border-left: 0;
        border-radius: 0 6px 6px 0;
    }
}

.ant-tabs-ink-bar {
    display: none;
}

.ant-tabs-top>.ant-tabs-nav::before {
    border: none;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
}

.library-images {
    max-height: 430px;
    overflow-y: scroll;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 40px;
}

.fav_diagnosis {
    i {
        cursor: pointer;
    }
}

.select-diagnosis {
    height: 44px;
    input {
        height: inherit;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: inherit;
    }
    //  .ant-select-item-option{    color: #5c5c5c;}
}

.ant-select-item-option {
    padding: 8px 12px;
    height: 44px;
}

.ant-select-selection-item .icon_info {
    width: 16px;
    height: 16px;
}

.ant-select-item-option-content .icon_info {
    display: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $primary;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $primary;
}

.ant-select-single .ant-select-selector {
    height: 44px !important;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
    min-height: 44px;
    height: auto;
    max-height: inherit;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: $primary;
    color: white;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding: 7px 24px 7px 0px;
    font-size: 16px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: white;
    font-weight: 600;
    background-color: $primary;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: white;
}

.ant-select-selection-item {
    color: #5c5c5c;
    .icon_star {
        margin-top: 3px;
        cursor: pointer;
    }
}

.ant-select-item-option-disabled {
    color: #5c5c5c;
    cursor: not-allowed;
}

.pills-library {
    button.selected::after {
        content: "";
        position: absolute;
        top: 8px;
        right: 18px;
        width: 14px;
        height: 28px;
        border-bottom: 3px solid #126dcb;
        border-right: 3px solid #126dcb;
        transform: rotate(45deg);
    }
}

.image-annotate-sketchpad {
    .upper-canvas {
        background-size: 400px;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.pills-freehand {
    .freehand-toolbar {
        position: absolute;
        top: 0;
        right: 0;
        ul {
            list-style: none;
            padding: 0;
            margin-bottom: 0;
            background-color: #d9d9d9;
            li {
                padding: 0.35rem 0.65rem;
                text-align: center;
            }
        }
    }
}

.custom-control-input.checked~.custom-control-label::after {
    background-color: $primary;
}

ul.listed-success {
    display: flex;
    flex-flow: column;
    padding: 0px 0px 0px 15px;
    li::marker {
        color: #00aa4c;
        width: 6px;
        height: 6px;
    }
    &.round-bullet-success {
        list-style-type: disc;
    }
}

ul.listed-black {
    display: flex;
    flex-flow: column;
    padding: 0px 0px 0px 15px;
    li::marker {
        color: #000000;
        width: 6px;
        height: 6px;
    }
    &.round-bullet-black {
        list-style-type: disc;
    }
}

.instructions-container {
    margin-bottom: 60px;
}

.ant-btn.template-save {
    line-height: 18px;
}

.templateError {
    max-width: 900px !important;
    width: 100% !important;
    .ant-modal-body {
        display: none;
    }
    .ant-modal-title {
        text-align: center;
        font-size: 1rem;
        margin: 1rem auto;
    }
    .ant-modal-footer {
        text-align: center;
        padding: 1rem 0px;
    }
}

.remove_search_icon .ant-select-arrow {
    display: none !important;
}