.row {
    margin-right: -8px;
    margin-left: -8px;
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-right: 8px;
    padding-left: 8px;
}

a {
    color: $primary;
}

strong,
b {
    font-weight: 500;
}

.rounded {
    overflow: hidden;
}

.navbar-light {
    .navbar-nav {
        .navbar-brand {
            >img {
                max-width: 150px;
            }
        }
        .nav-item {
            .nav-link {
                color: $black;
                padding: 0.35rem .55rem;
            }
        }
    }
    .profile-image {
        width: 41px;
        height: 41px;
        border-radius: 50%;
    }
}

.navbar-dark {
    background-color: $navbarColor;
    padding: 0;
    .nav-item {
        padding: .75rem 0;
        &:hover,
        &.active {
            background-color: $navbarActiveColor;
        }
        .nav-link {
            color: $white;
            font-size: 1.05rem;
            border-right: 1px solid $white;
            padding: 0 1.25rem;
        }
    }
}

.dropdown-toggle::after {
    border-top-color: $grey;
    border-bottom-color: $grey;
}

.nostyle-link {
    color: $color_text_default;
}

.nostyle-link:hover,
.nostyle-link:active,
.nostyle-link:focus {
    text-decoration: none;
}

.menu-dropdown {
    .dropdown-toggle::after {
        display: none;
    }
    .dropdown-menu {
        border-radius: 0;
        border: none;
        background-color: $primary;
        padding: .35rem 1rem;
        .dropdown-item {
            color: $white;
            padding: 0.5rem 3rem 0.5rem 0;
        }
        .dropdown-item:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
        .dropdown-item:hover {
            background-color: $primary;
        }
    }
}


/*---------------------------------------------
    Sidebar layout
----------------------------------------------*/

#page_sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    min-height: calc(100% - 155px);
    width: 230px;
    z-index: 810;
    &>div {
        position: sticky;
        top: 0px;
        border: 1px solid $borderColor;
    }
    .patient-section {
        border-bottom: 1px solid $borderColor;
    }
    .patient-section,
    .appointment-section {
        text-align: center;
        padding: 1rem;
        h1 {
            font-size: 1.1rem;
            color: #5c5c5c;
        }
        p {
            font-size: .875rem;
            margin-bottom: 2px;
        }
        .new_patient_section {
            font-size: 0.9rem;
            .highlight {
                color: #5c5c5c;
                font-size: 0.9rem;
                font-weight: 500;
            }
            .btn-checkout {
                background-color: #3c6cca;
                color: #fff;
                font-size: 0.8rem;
                padding: 5px 8px;
                font-weight: 500;
                &:hover {
                    background-color: #4976ce;
                    color: #fff;
                }
            }
        }
    }
    .menu-section {
        .sidemenu {
            padding: 0;
            margin: 0;
            .sidemenu-item {
                list-style: none;
                background-color: $lightgrey;
                .sidemenu-link {
                    display: block;
                    padding: .65rem 1rem;
                    color: $color_text_default;
                    border-bottom: 1px solid $white;
                }
                .sidemenu-link:hover,
                .sidemenu-link.active,
                .sidemenu-link:focus {
                    text-decoration: none;
                }
                .sidemenu-link.sidemenu-alert:focus {
                    background-color: $lightgrey;
                }
                .sidemenu-link.sidemenu-alert:hover,
                .sidemenu-link.sidemenu-alert.active {
                    background: #fadb40;
                    color: $color_text_default;
                }
                &:last-child {
                    .sidemenu-link {
                        border-bottom: none;
                    }
                }
            }
            .sidemenu-item {
                .sidemenu-link:hover,
                .sidemenu-link.active {
                    background-color: $color_text_default;
                    color: $color_white;
                }
            }
        }
    }
    .btn-sm.btn-outline-primary:hover {
        background-color: $primary !important;
        color: $color_white !important;
    }
}

#main {
    position: relative;
    min-height: 100vh;
    //margin-top: 1rem;
    margin-top: 0rem !important;
}

.page-title {
    font-size: 1.1rem;
    color: $grey;
}

.page-subtitle {
    font-size: 1.1rem;
    color: $default;
}

.content-wrapper {
    margin-left: 240px;
    margin-top: 0rem;
    margin-right: 0.5rem;
}

header .navbar.navbar-dark {
    padding: 0 0;
}

@media only screen and (max-width: $breakpoint-sm) {
    #page_sidebar {
        position: static;
        margin: 0;
        width: 100%;
    }
    .content-wrapper {
        margin: 0;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 940px;
    }
}