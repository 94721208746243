.healtvc-card-title,
.healtvc-apptime {
    /* font-size: 18px; */
    // font-size: 0.9vw;
    font-size: 0.86vw;
    text-transform: capitalize;
    // font-weight: 500 !important;
    white-space: nowrap;
}

.card-bodycustom {
    box-shadow: 0 0 10px 6px rgba(115, 115, 115, 0.1);
    border: 1px solid rgba(115, 115, 115, 0.3);

    .healtvc-apptime {
        white-space: nowrap;

        &.pv {
            font-weight: 400 !important;
        }

        >span {
            &:first-child {
                color: #333333;
                font-size: 14px;
                margin-right: 5px;
                display: flex;
                align-items: center;

                img {
                    height: auto !important;
                    width: 21px;
                    margin-right: 5px;
                }
            }

            &.late-span {
                color: $primary;
                /* background-color: #d24350; */
                margin-left: 5px;
                font-size: 14px !important;
            }
        }
    }

    .theme-text {
        color: $primary !important;
        font-weight: 600;
    }

    .text-age {
        color: #666 !important;
        font-weight: 400;
    }

    .tooltip {
        &-img {
            text-transform: lowercase;
            height: 16px;
            width: 16px;
            line-height: 16px;
            font-size: 14px;
            background: #6e6e6e;
            color: $white;
            border-radius: 100px;
            text-align: center;
        }

        &-text {
            position: absolute;
            width: 315px;
            top: 33px;
            right: -134px;
            display: none;
            padding: 7px 24px 1px 19px;
            background-color: hsla(0, 0%, 43.1%, .953);
            border-radius: 6px;
            color: $white;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            z-index: 1;
            border: 1px solid hsla(0, 0%, 43.1%, .953);
        }
    }

    .tooltip-img:hover~.tooltip-text {
        display: block !important;
    }

    .readings {
        // width: 59px;
        // height: 38px;
        // border: 1px solid hsla(0, 0%, 71.4%, .47);

        .small {
            font-size: 12px;
        }

        .small-unit {
            font-weight: normal;
            margin-left: .5rem;
            color: rgb(92, 92, 92);
        }

        &.bp {
            width: 88px;
        }

        .text-vital-normal {
            color: #548235;
        }

        .border-text-readings {
            // margin-top: -12px;
            // margin-left: 5px;
            // background-color: $white;
            // width: -webkit-fit-content;
            // width: -moz-fit-content;
            // width: fit-content;
        }

        .reading-value {
            font-size: 16px;
        }

        &-size {
            font-size: 18px;
            margin-top: 11px;

            >div {

                flex: 1;
                margin-left: 1rem;
                padding-left: 1rem;
                border-left: 1px solid;
                white-space: nowrap;

                &:first-child {
                    margin: 0;
                    padding: 0;
                    border: none;
                }


                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-gap {
            // margin-right: 32px;
        }
    }
}