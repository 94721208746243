.collapse-wrapper .ant-collapse-header .anticon {
  display: none !important;
}

.collapse-wrapper .ant-collapse-header {
  padding-left: 15px !important;
}

.accordianstyle.panelcol.notes-panel {
    .ant-collapse-header {
        font-size: 17.5px !important;
        font-weight: 400;
    }

    .ant-collapse-content {
        textarea {
            font-size: 14px;
            height: 100%;
        }
    }
}