.multi_party_calling {
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 999;
  position: fixed;
  background-color: #fff;
}
.multi_party_calling > div {
  display: block;
  width: 100%;
}
.multi_party_calling_footer {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  z-index: 99;
}
.multi_party_calling.draggable_layout {
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.compact_layout {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.draggable_layout {
  top: 7%;
  right: 5%;
  bottom: 0;
  width: 20%;
  height: 530px;
}
.multi_party_calling_body { position: absolute; bottom: 0; right: 0; left: 0; }
.draggable_layout .multi_party_calling_body { top: 24px; }
.compact_layout .multi_party_calling_body { top: 0; }
/* .compact_layout .frameStyling { max-height: 250px; }
.draggable_layout .frameStyling { min-height: 105px; max-height: 105px; }
.draggable_layout video { min-height: 105px; max-height: 105px; }
.draggable_layout .frames { min-height: 105px; max-height: 105px; } */
/* .multi_party_calling.compact_layout .frameStylingImg1, .frameStylingImg2, .frameStylingImg3, .frameStylingImg4 { height: 250px; }
.multi_party_calling.draggable_layout .frameStylingImg1, .frameStylingImg2, .frameStylingImg3, .frameStylingImg4 { height: 125px; }
.frameStylingImg1 { background-size: cover; background-repeat: no-repeat; width: 100%; background-image: url('https://www.ovt.com/wp-content/uploads/2021/12/MachineVision_FrontFacing3DCamera_Detail_2.jpg'); }
.frameStylingImg2 { background-size: cover; background-repeat: no-repeat; width: 100%; background-image: url('https://thumbs.dreamstime.com/z/serious-man-scratching-head-thinking-deeply-problem-trying-to-solve-looking-up-making-decision-white-wall-background-157380650.jpg'); }
.frameStylingImg3 { background-size: cover; background-repeat: no-repeat; width: 100%; background-image: url('https://www.mactrast.com/wp-content/uploads/2018/09/iPhone_XS_Max_Selfiegate-800x457.jpg'); }
.frameStylingImg4 { background-size: cover; background-repeat: no-repeat; width: 100%; background-image: url('https://t4.ftcdn.net/jpg/03/03/54/59/360_F_303545914_XLEAix36kSd78qCl4XYhl2S1iYSA9IyW.jpg'); } */
/* .draggable_layout .w_100pcent { height: 105px; }
.draggable_layout .frames > div { height: 105px; } */
.w_100pcent {
  width: 100%;
  float: left;
  position: relative;
}
.w_50pcent {
  width: 50%;
  float: left;
  position: relative;
}
.w_80pcent {
  width: 80%;
  float: left;
  position: relative;
}
/* .w_80pcent video { width: 100%; height: 500px; } */
.w_20pcent {
  width: 20%;
  float: left;
  position: relative;
}
.video_options {
  position: absolute;
  right: 10px;
  top: 5px;
  background-color: #000;
  border-radius: 10px;
  color: #fff;
}
.video_options button {
  color: #fff;
  padding: 1px 3px 3px 4px;
  line-height: 0;
}
.video_options button:hover {
  color: #fff;
}
/* .compact_layout .w_50pcent .frames, 
.compact_layout .w_50pcent video, 
#vc_multiparty_subscribers.w_50pcent > div { height: 250px !important;}
.compact_layout .w_80pcent .frames, 
.compact_layout .w_80pcent video { height: 500px; }
.compact_layout #vc_multiparty_subscribers.w_20pcent > div { height: 150px !important;} */

/* #vc_multiparty_subscribers > div, #vc_doctor_screen_container { display: inline-block !important; } */

/* Assuming the name of the PreviewPublisher's container is vc_multiparty_publisher */
#vc_multiparty_publisher {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  border-color: black;
  /* border-radius: 5px; */
  border-style: dashed;
  width: 640px;
  height: 480px;
  padding: 20px;
}

/* Assuming the name of the Room's container is vc_multiparty_subscribers */
#vc_multiparty_subscribers {
  width: 100vw;
  /* height: calc(100vh - 50px); */
  background-color: #ddd;
  position: relative;
}
.draggable_layout #vc_multiparty_subscribers { height: calc(100vh - 50px); }
.compact_layout #vc_multiparty_subscribers { height: calc(100vh - 0px); }

#vc_multiparty_subscribers > .OT_publisher {
  top: 25px;
  right: 25px;
  position: absolute;
  /* border-radius: 10px; */
}
#vc_multiparty_subscribers > .OT_screenshare {
  top: 25px;
  left: 25px;
  position: absolute;
  /* border-radius: 10px; */
}

.draggable_layout #layoutContainer { width: 260px !important; }
.draggable_layout #layoutContainer > div {width: 240px !important; height: 105px !important; position: static !important; float: left !important;}
/* .topRightOpts { background-color: #000; position: absolute; top: 0; right: 0; padding-bottom: 13px; opacity: 0.6; border-radius: 0 0 0 30px; padding-left: 7px; z-index: 99; }
.topRightOpts button, .topRightOpts button:hover { color: #fff; } */
/* .topLeftOpts { background-color: #000; position: absolute; top: 0; left: 0; padding: 4px 3px 9px 4px; opacity: 0.6; border-radius: 0 0 30px 0; z-index: 99; }
.topLeftOpts button, .topLeftOpts button:hover { color: #fff; } */

.hidden, .publisher-audio-button { display: none; }
.control-btn.minimize-btn, .control-btn.adddoc-btn {background-color: #2279F9;border-radius: 15px;border: none;padding: 0px 5px 4px 5px;color: #ffffff;margin-left: 7px;line-height: 25px;font-size: 22px;}
.control-btn.adddoc-btn{background-color: #28a745;}
.drag-item-panel {background-color: #1E1D1D;text-align: center;color: #ffffff;font-weight: bold; cursor: grab;cursor: -moz-grab;cursor: -webkit-grab;cursor: move;}
.drag-item-panel:active {cursor: -moz-grabbing;cursor: -webkit-grabbing;cursor: grabbing;}

/* Vonage video elements CSS */
.video-audio-button {display: none !important;}