.menstrual__lmpPeriodStartDate {
    height: 60px;
    background-color: #f6f8fa;
    border: 1px solid #b8bcbc;
    color: #000;
    position: relative;
}

.period__startDates {
    display: flex;
    align-items: center;
    justify-content: space-around;

    a.active {
        color: #d24350;
        text-decoration: underline;
    }

    a:hover {
        color: #d24350 !important;
        text-decoration: underline !important;
    }
}

// .period__startDates > a.active {
//     color: #d24350;
// }

.menstrual__colorLegends {
    position: absolute;
    top: 141px;
    right: 29px;

    .menstrual__period {
        display: flex;

        .period__color {
            width: 13px;
            height: 13px;
            display: block;
            margin-right: 5px;
            position: relative;
            top: 3px;
        }

        .period__colorPink {
            background-color: pink;
            border-radius: 2px;
        }

        .period__colorGreen {
            background-color: #a9c370;
            border-radius: 2px;
        }
    }
}

.menstrual__lmpCalender {
    border: 1px solid #ddd;
    padding: 20px;

    .fc-day-top {
        text-align: center;
        position: relative;
        top: 18px;
    }

    .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
        float: unset;
    }

    .fc-event {
        position: relative;
        width: 16px;
        display: block;
        font-size: 0.85em;
        line-height: 1.4;
        border-radius: 2px;
        border: 0 !important;
        top: -32px;
        right: -48px;
    }

    .fc-event,
    .fc-event-dot {
        background-color: inherit;
        color: inherit;
        font-weight: 500;
    }

    .fc-bg td.fc-today {
        background-color: #d24350 !important;
        color: #fff;
        font-weight: 500;
    }

    .fc-unthemed td.fc-today {
        color: #fff;
        font-weight: 500;
    }
    .fc-toolbar h2 {
        font-size: 1.5em !important;
    }

    .fc-unthemed th,
    .fc-unthemed td,
    .fc-unthemed thead,
    .fc-unthemed tbody,
    .fc-unthemed .fc-divider,
    .fc-unthemed .fc-row,
    .fc-unthemed .fc-content,
    .fc-unthemed .fc-popover,
    .fc-unthemed .fc-list-view,
    .fc-unthemed .fc-list-heading td {
        border: 0 !important;
    }

    .fc-day-grid-event .fc-content {
        text-align: center;
    }

    table {
        border-collapse: separate !important;
        border-spacing: 10px !important;
    }

    .fc-dayGrid-view .fc-body .fc-row {
        min-height: 73px;
    }

    .fc-highlight-skeleton .fc-highlight,
    .fc-unthemed td.fc-today,
    .fc-bgevent-skeleton .fc-bgevent {
        border-radius: 3px;
        overflow-y: hidden;
    }
}

.fc-widget-content,
.fc-scroller,
.fc-day-grid-containers {
    overflow: hidden !important;
}

.fc-day-grid-container{
  height: auto !important;
}

.fc-left,
.fc-prev-button,
.fc-button,
.fc-button-primary {
    background-color: #fff !important;
    border: none !important;
}
.fc-button .fc-icon {
    color: black !important;
    font-size: 30px;
}
.fc-button-primary:focus {
    box-shadow: none !important;
}

/*.fc-day-top, .fc-past , .fc-today , .fc-future {
    border-color:black;
    border-collapse:collapse;
    box-shadow: 0 0 1px 1px rgba(76, 91, 106, 0.5);
    
} */
