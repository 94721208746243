.copy_vitalsModal{
    max-width: 900px !important;
    width: 100% !important;
    .modal-title {
        font-size: 1.125rem;
    }
}
.CopyAssesmentModal{
    max-width: 600px !important;
    width: 100% !important;
    .ant-modal-body{
        padding: 10px 8px;
    }
}